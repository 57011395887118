<template>
  <va-card>
    <va-card-title class="cardTitle">
      {{ $t("entitlements.title") }}

      <va-button
        class="ml-2"
        color="primary"
        @click="openCreateEntitlementModal"
      >
        {{ $t("buttons.createEntitlement") }}
      </va-button>
    </va-card-title>

    <!-- Table -->
    <va-card-content>
      <va-data-table
        :no-data-html="$t('entitlements.noResults')"
        :columns="columns"
        :items="state.entitlements"
      >
        <template #header(id)>{{ $t("entitlements.id") }}</template>
        <template #header(product)>{{ $t("entitlements.product") }}</template>
        <template #header(ownedBy)>
          <span class="has-tip" :title="$t('entitlements.ownedByExplain')">
            {{ $t("entitlements.ownedBy") }}
          </span>
        </template>
        <template #header(expiresAt)>{{
          $t("entitlements.expiresAt")
        }}</template>
        <template #header(status)>{{ $t("entitlements.status") }}</template>

        <template #cell(id)="{ source: id }">
          <router-link :to="{ path: entitlementLink(id) }">
            {{ id }}
          </router-link>
          <button-clipboard-copy :field="$t('entitlements.id')" :value="id" />
        </template>

        <template #cell(product)="{source: product}">
          <span class="badges">
            {{ product.name }}
            <va-badge
              v-if="product.isTrial"
              :text="$t('entitlements.trial')"
              color="#791ec9"
            />
          </span>
        </template>

        <template #cell(expiresAt)="{source: expiresAt}">
          {{ expiresAt ? printDate(expiresAt) : "Never" }}
        </template>
      </va-data-table>
    </va-card-content>
    <va-card-content>
      <va-pagination
        class="pagination"
        input
        color="#000000"
        :hide-on-single-page="true"
        v-model="state.currentPage"
        :pages="state.totalPages"
        :page-size="state.perPage"
      />
    </va-card-content>
    <create-entitlement-modal
      v-if="state.showCreateEntitlementModal"
      @close="closeCreateEntitlementModal"
    />
  </va-card>
</template>

<style scoped>
.has-tip::after {
  font-family: "Glyphicons Halflings";
  content: "\e085";
  margin-left: 1px;
}
.badges {
  display: flex;
  gap: 5px;
}
</style>

<script setup>
import { reactive, watch, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { callFetchEntitlements } from "@/api/licensing";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import CreateEntitlementModal from "@/components/modal/CreateEntitlementModal.vue";
import { printDate } from "@/utils";
import { entitlementLink, getEntitlementStatus } from "@/utils/licensing";

const handleError = inject("handleError");
const { t } = useI18n();
const store = useStore();

const columns = [
  "id",
  "product",
  "templateId",
  "ownedBy",
  "expiresAt",
  "status"
];

const state = reactive({
  entitlements: [],
  loading: false,
  lastLoadedPage: undefined,
  currentPage: 1,
  totalItems: 0,
  perPage: 10,
  totalPages: 0,
  showCreateEntitlementModal: false
});

const props = defineProps({
  params: {
    type: Object
  }
});

/**
 * Verifies whether given entitlement is owned by entity for which this
 * entitlement table is being displayed.
 * @param {Object} entitlementItem Entitlement item from API
 * @returns {boolean} Whether current entity owns this entitlement.
 */
function isOwner(entitlementItem) {
  return (
    !!entitlementItem.owner?.id &&
    (entitlementItem.owner.id === props.params.ownerId ||
      entitlementItem.owner.id === props.params.userId)
  );
}

/**
 * Create the parameters object for the request and call it.
 * Then calculate the page properties's values.
 * @param {number} page - positive integer
 */
async function loadEntitlements(page = 1) {
  if (state.loading === true || page === state.lastLoadedPage) {
    return;
  }

  state.lastLoadedPage = page;
  state.loading = true;

  const params = buildUrlParams(page);

  try {
    const resp = await callFetchEntitlements({ params });
    if (resp?.data) {
      const entitlements = resp.data.items.map(item => ({
        id: item?.id,
        product: {
          name: item.productName || item.productId, // Ensure that product name is included in the entitlement data
          isTrial: item?.isTrial ?? false
        },
        templateId: item.templateId,
        ownedBy: isOwner(item) ? t("general.yes") : t("general.no"),
        expiresAt: item.expiresAt,
        status: t("entitlements.statusEnums." + getEntitlementStatus(item))
      }));

      state.entitlements = entitlements;

      updatePagination(resp.data.total, page);
    }
  } catch (e) {
    handleError(e);
  }

  state.loading = false;
}

function buildUrlParams(page = 1) {
  const params = new URLSearchParams();

  Object.keys(props.params).forEach(key => {
    if (props.params[key] !== undefined) {
      if (Array.isArray(props.params[key])) {
        props.params[key].forEach(p => params.append(key, p));
      } else {
        params.append(key, props.params[key]);
      }
    }
  });

  params.set("count", state.perPage);
  params.set("page", page);

  return params;
}

function updatePagination(totalItems, page = 1) {
  state.totalItems = totalItems;
  state.currentPage = page;
  state.totalPages =
    Math.floor(totalItems / state.perPage) +
    (totalItems % state.perPage === 0 ? 0 : 1);
}

watch(
  () => state.currentPage,
  async currentPage => {
    await loadEntitlements(currentPage);
  }
);

watch(
  () => props.update,
  async () => {
    await loadEntitlements();
  }
);

onMounted(async () => {
  await loadEntitlements();
});

const openCreateEntitlementModal = () => {
  state.showCreateEntitlementModal = true;
};

const closeCreateEntitlementModal = () => {
  state.showCreateEntitlementModal = false;
};
</script>
<style scoped>
.card-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
