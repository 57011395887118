import { printDate } from "@/utils";
/**
 * Create and return the link to the license dedicated page
 * @param {string} licenseId
 * @returns {string}
 */
export const licenseLink = licenseId => {
  if (!licenseId) {
    return;
  }
  return `/admin/licensing/licenses/${licenseId}`;
};

/**
 * Create and return the link to the entitlement dedicated page
 * @param {string} entitlementId
 * @returns {string}
 */
export const entitlementLink = entitlementId => {
  if (!entitlementId) {
    return;
  }
  return `/admin/licensing/entitlements/${entitlementId}`;
};
/**
 * Create and return the link to the template dedicated page
 * @param {string} templateId
 * @returns {string}
 */
export const templateLink = templateId => {
  if (!templateId) {
    return;
  }
  return `/admin/products/templates/${templateId}`;
};

/**
 * Create and return the link to the template dedicated page
 * @param {string} productId
 * @returns {string}
 */
export const productLink  = productId => {
  if (!productId) {
    return;
  }
  return `/admin/products/${productId}`;
};

/**
 * Retrieve license ID from its key, is ok because of key unicity.
 * @param {string} key
 * @returns {string}
 */
export const getIdFromKey = (licenses, key) => {
  const license = licenses.find(license => license.key === key);
  return license.id;
};

/**
 * Return the entitlement validity period based on parameters.
 * @param {number} validityDuration
 * @param {number} expiresAt
 * @returns {string}
 */
export const getEntitlementValidityPeriod = (
  expiresAt,
  t
) => {
  return expiresAt
    ? printDate(expiresAt)
    : t("entitlements.permanent");
};

/**
 * Returns whether the entitlement has expired.
 * @param {Object} entitlement Entitlement
 * @param {string?} entitlement.expiresAt Entitlement expiration date
 * @returns {boolean} Whether entitlement expired.
 */
export const isEntitlementExpired = (entitlement) => {
  if (!entitlement.expiresAt) {
    return false;
  }

  return Date.now() - Date.parse(entitlement.expiresAt) >= 0;
}

/**
 * Returns a string that describes the status of the entitlement
 * @param {Object} entitlement Entitlement
 * @param {string?} entitlement.expiresAt Entitlement expiration date
 * @param {boolean} entitlement.suspended Whether entitlement is suspended
 * @param {boolean} entitlement.revoked Whether entitlement is revoked
 * @returns {string} Entitlement description status.
 */
export const getEntitlementStatus = (entitlement) => {
  if (!entitlement) {
    return "";
  }

  if (entitlement.revoked) {
    return "revoked";
  }

  if (entitlement.suspended) {
    return "suspended";
  }

  if (isEntitlementExpired(entitlement)) {
    return "expired";
  }

  return "active";
}
